<template>
  <div>
    <b-card
      title=""
      class="mt-2"
    >
      <!-- <h2>よく利用する一覧</h2>
      <div class="">
        目的のページに直ぐにアクセスできます。
      </div> -->
      <b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          size="md"
          class="btnSpace_ mt-1"
          @click="move('teacher-lesson')"
        >
          講座の登録をする
        </b-button>
      </b-row>
      <!-- <b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          block
          size="md"
          class="btnSpace_ mt-1"
          @click="move('tutor-profile-edit')"
        >
          プロフィールを更新する
        </b-button>
      </b-row>
      <b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          block
          size="md"
          class="btnSpace_ mt-1"
          @click="move('tutor-account')"
        >
          環境設定を更新する
        </b-button>
      </b-row>
      <b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          block
          size="md"
          class="btnSpace_ mt-1"
          @click="move('user-account')"
        >
          アカウント情報を更新する
        </b-button>
      </b-row> -->
      <div class="text-white">
        <small>tutor dashboad link shortcut</small>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCard, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    // BCol,
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    move(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>

<style scoped>
.coinDisplay {
  font-size: 1.6rem;
  font-weight: 600;
}
.payday_ {
  font-size: 0.7rem;
  color: rgb(165, 165, 165);
}
.payDate {
  font-size: 1.4rem;
  font-weight: 600;
}
.linkSpace_ {
  width: 95%;
  margin: 1% auto;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  padding: 2%;
  background-color: rgb(236, 238, 247);
  font-size: 1.4rem;
}
.btnSpace_ {
  width: 95%;
  margin: 1% auto;
}
</style>
