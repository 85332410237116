<template>
  <div class="space-basic">
    <DashBoadMain />
  </div>
</template>

<script>
import DashBoadMain from '@/components/teacher/dashboad/DashBoadMain.vue'

export default {
  components: {
    DashBoadMain,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
}
</script>
