<template>
  <div class="mt-2">
    <h2>ダッシュボード</h2>
    <div>
      <b-alert
        variant="primary"
        show
        class=""
      >
        <div class="alert-body mt-1 mb-2">
          <div class="d-flex">
            <div class="">
              <b-avatar
                size="40"
                src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
                variant="light-primary"
              />
            </div>
            <div class="ml-1">
              講師専用のダッシュボードです。よく使うページに簡単にアクセスできます。
            </div>
          </div>
        </div>
      </b-alert>
    </div>
    <ShortCut />
  </div>
</template>

<script>
import {
  BAvatar, BAlert,
} from 'bootstrap-vue'
import ShortCut from '@/components/teacher/dashboad/ShortCut.vue'

export default {
  components: {
    BAvatar,
    BAlert,
    ShortCut,
  },
}
</script>
